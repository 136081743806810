import React from 'react'

const ReturnRU = () => {
  return (
    <div className='info container-padding'>
      <h2 className='page-header base-header'>
        Return and Exchange
      </h2>
      <div className='base-text'>
        <p>
          We offer a 14 day returns policy on full priced items. Unfortunately we can not offer returns on sale, discounted or auction items. If you wish to return the goods please notify us via email to <a href="mailto:yo@theindigostuff.com" className='link font-thin'>yo@theindigostuff.com</a>.
        </p>
        <p>
          Customers should return items to us using their local postal service. Please note that return postage costs incurred in an exchange are payable by you.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Exchange
      </h3>
      <div className='base-text'>
        <p>
          If you would like to exchange an item please notify us via email to <a href="mailto:yo@theindigostuff.com" className='link font-thin'>yo@theindigostuff.com</a>. Most of the items items are in one size and in one color. You can exchange your item if something is wrong.
        </p>
      </div>
      <h3 className='base-text font-bold'>
        Refunds
      </h3>
      <div className='base-text'>
        <p>
          We aim to process returns within 2 working days of receiving them. As long as the returned items meet our refund criteria, your refund will be with you 24-48 hours after your return is processed, depending on your card issuer.
        </p>
      </div>
    </div>
  )
}

export default ReturnRU
