import React from 'react'

function ImageFader() {
  const images = [
    'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2023-black-friday%2Fbf%2023%20banner%201_1.png?alt=media&token=927b2ead-0c1d-4619-b18e-715d8ff692b8',
    'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2023-black-friday%2Fbf%2023%20banner%202_1.png?alt=media&token=02e3e665-df13-413f-b1b4-847027c8c607',
    'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2023-black-friday%2Fbf%2023%20banner%203_1.png?alt=media&token=15e1bde5-aa21-4f18-8eb7-5b6d4d772e29',
    'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2023-black-friday%2Fbf%2023%20banner%204.png?alt=media&token=84e77dce-39e4-458a-baf5-fabd47ebb547',
    'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2023-black-friday%2Fbf%2023%20banner%205_2.png?alt=media&token=e56bd8cb-9ba1-47fd-84dd-b04662c34add',
    'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2023-black-friday%2Fbf%2023%20banner%206_1.png?alt=media&token=1fc0735f-f5bf-4f9b-8263-1199dffae698',
    'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2023-black-friday%2Fbf%2023%20banner%207_1.png?alt=media&token=c406a7dc-69fe-4a96-a34b-0ed3a633a4b3'
  ]

  return (
    <div className='image-fader'>
      <div className='image-fader__inner'>
        {
          images.map((image, i)=> {
            return (
              <img key={i} src={image} width='300' alt='BLACK FRIDAY 2023' />
            )
          })
        }
      </div>
    </div>
  )
}

export default ImageFader
