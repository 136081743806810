import React from 'react'

function SecondBanner() {
  return (
    <div className='items-container__banner items-container__banner--homeless'>
        <div className='items-container__banner-image--portrait'>
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fmobi-ban-2.jpg?alt=media&token=542b2f0a-1ca2-4659-ab27-8b119b0cbab1' width='250' alt='INDIGO STUFF' />
        </div>
        <div className='items-container__banner-image--landscape items-container__banner-image--landscape-two-imgs'>
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fdesk-2.jpg?alt=media&token=ccf91ebc-244c-47c3-8b45-0c64ce33189b' width='250' alt='INDIGO STUFF' />
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fdesk-3.jpg?alt=media&token=5a0bc988-c1d0-4a37-9d1e-517896413a56' width='250' alt='INDIGO STUFF' />
        </div>
    </div>
  )
}

export default SecondBanner
