import React from 'react'
import ItemsList from '../Items/ItemsList'
import { sortItems } from '../../utils'

const PM = ({ items, locale, drate }) => {
  if (!items || !items.length) return null

  return (
    <div className='stuff'>
      <ItemsList
          items={sortItems(items.filter(item => item.collection === 'pm-x-indigo-stuff' && !item.hide))}
          locale={locale}
          moreStuffRoute={'/stuff'}
          drate={drate}
          project='pm'
      />
    </div>
  )
}

export default PM
