export const collectionsTextData = {
  en: {
    indigo: {
      name: 'INDIGO STUFF',
      description: 'Alternative streetwear brand. One Size – One Love. Unisex.'
    },
    sale: {
      name: 'SALE',
    },
    market: {
      name: 'GARAGE SALE MARKET',
      description: 'Custom design platform, INDIGO STUFF samples, second hand, offline parties.'
    },
    pm: {
      name: 'POSHLAYA MOLLY',
      description: 'POSHLAYA MOLLY x INDIGO STUFF merch collection.'
    },
    psy: {
      name: 'PSYCHEA',
      description: 'Limited PSYCHEA x INDIGO STUFF merch collection. Based on sketches by Dmitry Porubov. Designed by Lyu Chirkova.'
    },
    emo: {
      name: 'EMOLAND',
      description: 'EMOLAND x INDIGO STUFF merch collection.'
    }
  },
  ru: {
    indigo: {
      name: 'INDIGO STUFF',
      description: 'Бренд альтернативной уличной одежды. Один размер – одна любовь. Унисекс.'
    },
    sale: {
      name: 'SALE',
    },
    market: {
      name: 'GARAGE SALE MARKET',
      description: 'Платформа для кастом дизайнеров, сэмплы бренда INDIGO STUFF, секонд-хенд, оффлайн вечеринки.'
    },
    pm: {
      name: 'ПОШЛАЯ МОЛЛИ',
      description: 'Коллекция мерча ПОШЛАЯ МОЛЛИ х ИНДИГО СТАФФ.'
    },
    psy: {
      name: 'ПСИХЕЯ',
      description: 'Лимитировання коллекция ПСИХЕЯ х ИНДИГО СТАФФ. Основана на скетчах Дмитрия Порубова. Дизайн создан Лю Чирковой.'
    },
    emo: {
      name: 'EMOLAND',
      description: 'Мерч вечеринки EMOLAND x INDIGO STUFF.'
    }
  }
}
