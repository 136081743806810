import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

function ImageGallery({ images }) {
  const elements = images.map((imageLink, i) => {
    return (
      <div key={imageLink + i}>
        <img src={imageLink} alt='PARIS 2023' />
      </div>
    )
  })

  return (
    <div className='image-gallery'>
      <Carousel
        showIndicators={false}
        showStatus={false}
        autoFocus={true}
        autoPlay={true}
        useKeyboardArrows={true}>
        {elements}
      </Carousel>
    </div>
  )
}

export default ImageGallery
