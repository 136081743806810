import React from 'react'

function FourthBanner() {
  return (
    <div className='items-container__banner items-container__banner--homeless'>
        <div className='items-container__banner-image--portrait'>
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fmobi-ban-5.jpg?alt=media&token=d4bfdb1e-96bf-4d77-8f6c-33d4880d1ed2' width='250' alt='INDIGO STUFF' />
        </div>
        <div className='items-container__banner-image--landscape'>
          <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fdesk-5.jpg?alt=media&token=d77901ef-3203-4a80-9059-4d2f5ce82684' width='250' alt='INDIGO STUFF' />
        </div>
    </div>
  )
}

export default FourthBanner
