import React, { Component } from 'react'

class FirstBanner extends Component {
  render() {
    // banner = <BlackFridayBanner title='BLACK FRIDAY' />
    // bannerDesk = <BlackFridayBannerDesk title='BLACK FRIDAY' />
    // classBanner = 'items-container__banner'


    // firstBanner =
    //   <div className='items-container__banner items-container__banner--homeless'>
    //     <NavLink to='/'>
    //       <img src='https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2Fhomeless%2Fbanner_01.png?alt=media&token=c5d1649d-0a6d-48d0-ba48-0d3619a63bbb' width='250' alt='HOMELESS COLLECTION' />
    //     </NavLink>
    //   </div>

    // 14 feb
    // let banner = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2Fhomeless%2Fbanner_sale140223_mobile-2.png?alt=media&token=b8580665-6fcb-47bb-ae61-a8c9a5b95012'
    // let bannerDesk = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2Fhomeless%2Fbanner_sale140223_desktop-2.png?alt=media&token=50a7b983-9fb2-426e-ba14-5c6a33ef520c'



    // xz 04.06.24 commented
    // let banner = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2Fhomeless%2Fbanner_mobile_01.png?alt=media&token=83b2f3bb-9c9b-4171-a9c6-f57451409ca0'
    // let bannerDesk = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2Fhomeless%2Fbanner_01--cropped.png?alt=media&token=63451868-69f0-45e3-bb77-514a1ea6b0a9'

    // <div className='items-container__banner items-container__banner--homeless'>
    //     <div className='items-container__banner-image--portrait'>
    //       <NavLink to='/'>
    //         <img src={banner} width='2000' height='3000' alt='HOMELESS COLLECTION' />
    //       </NavLink>
    //     </div>
    //     <div className='items-container__banner-image--landscape'>
    //       <NavLink to='/'>
    //         <img src={bannerDesk} width='2000' height='2000' alt='HOMELESS COLLECTION' />
    //       </NavLink>
    //     </div>
    // </div>

    let banner = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fmobi-ban-1.jpg?alt=media&token=dc1ede4d-2a9e-4634-8fac-57f11efeb307'
    let bannerDesk = 'https://firebasestorage.googleapis.com/v0/b/indigostore-494ff.appspot.com/o/static%2F2024-06-sale-update%2Fdesk-1.jpg?alt=media&token=f86677d0-4335-4276-b5da-6aa6070f06c0'

    return (
      <div className='items-container__banner items-container__banner--homeless'>
          <div className='items-container__banner-image--portrait'>
            <img src={banner} width='2000' height='3000' alt='INDIGO STUFF' />
          </div>
          <div className='items-container__banner-image--landscape'>
            <img src={bannerDesk} width='2000' height='2000' alt='INDIGO STUFF' />
          </div>
      </div>
    )
  }
}

export default FirstBanner
