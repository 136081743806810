import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { headerTextData } from '../../data/header'
import { ReactComponent as IndigoLogo } from '../../svg/indigostorelogo.svg'
import { ReactComponent as BucketWhiteIcon } from '../../svg/bucket-white.svg'
import { ReactComponent as BucketIcon } from '../../svg/bucket.svg'
import { ReactComponent as MarketLogo } from '../../svg/marketlogo.svg'

class Header extends Component {
  state = {
    navOpened: false
  }

  // componentDidMount() {
  //   const marketPage = this.props.history.location.pathname.includes('market')
  //
  //   if (marketPage) {
  //     document.documentElement.style.setProperty('--indigo-color', '#e42037')
  //     document.querySelector('meta[name="theme-color"]').setAttribute("content", '#e42037')
  //   } else {
  //     document.documentElement.style.setProperty('--indigo-color', 'rgb(62, 80, 157)')
  //     document.querySelector('meta[name="theme-color"]').setAttribute("content", 'rgb(62, 80, 157)')
  //   }
  // }

  componentDidUpdate(prevProps) {
    // const marketPage = this.props.history.location.pathname.includes('market')
    // const marketPage = this.props.history.location.pathname === '/market'
    // const salePage = this.props.history.location.pathname === '/sale'
    // const pmPage = this.props.history.location.pathname === '/poshlaya-molly'
    // const stuffPage = this.props.history.location.pathname === '/stuff'
    // const shopBlock = document.getElementById('shopitems')

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.closeNav()
      window.scrollTo(0, 0)
      // if (marketPage) {
      //   document.documentElement.style.setProperty('--indigo-color', '#e42037')
      //   document.querySelector('meta[name="theme-color"]').setAttribute("content", '#e42037')
      // } else {
      //   document.documentElement.style.setProperty('--indigo-color', 'rgb(62, 80, 157)')
      //   document.querySelector('meta[name="theme-color"]').setAttribute("content", 'rgb(62, 80, 157)')
      // }
    }

    // TODO: move it to Each Route componentDidMount
    // this.unlisten = this.props.history.listen((location, action) => {
    //   window.scrollTo(0, 0)
    //   this.closeNav()
    // })
  }

  // componentWillUnmount() {
  //   this.unlisten()
  // }

  render() {
    const { user, bucketAmount, locale, history, changeLocale } = this.props
    const { navOpened } = this.state
    const headerData = headerTextData[locale]

    const replaceBucketIconWithWhiteIcon = history.location.pathname === '/checkout'
    // const homePage = history.location.pathname === '/'
    const marketPage = history.location.pathname.includes('market')
    // const hofmannitaPage = history.location.pathname.includes('hofmannita') || history.location.pathname.includes('hofmanitta')

    const headerClass = classnames({
      'header': true,
      // 'container': true,
      'header--nav-open': navOpened,
      // 'header--home-page': homePage
    })

    const burgerClass = classnames({
      'burger': true,
      'burger--open': navOpened,
      // 'burger--home-page': homePage
    })

    const navClass = classnames({
      'header__nav': true,
      'header__nav--open': navOpened,
      // 'header__nav--home-page': homePage
    })

    const cartClass = classnames({
      'header__cart': true,
      // 'header__cart--home-page': homePage
    })

    const headerLogoClass = classnames({
      'header__logo': true,
      'header__logo--hidden': marketPage
    })

    const saleLinkClass = classnames({
      'header__nav-link header__nav-link--sale': true,
      'header__nav-link header__nav-link--sale header__nav-link--market-page': marketPage
    })

    let logoElement =
      <NavLink to='/'>
        <IndigoLogo title='INDIGO STUFF' />
      </NavLink>

    // if (hofmannitaPage) {
    //   logoElement =
    //     <NavLink to='/'>
    //       <MarketLogo title='HOFMANNITAS GARAGE SALE' />
    //     </NavLink>
    // } else

    if (marketPage) {
      logoElement =
        <NavLink to='/'>
          <MarketLogo title='GARAGE SALE MARKET' />
        </NavLink>
    }

    // if (homePage) return null

    return (
      <header className={headerClass}>
        <div className='header__inner'>
          <div className='header__burger'>
            <div className={burgerClass} onClick={this.toggleNav}>
              <span className='burger__part'></span>
              <span className='burger__part'></span>
              <span className='burger__part'></span>
              <span className='burger__part'></span>
            </div>
          </div>
          <div className={headerLogoClass}>
            {logoElement}
          </div>
          <div className={cartClass}>
            {
              locale === 'ru' ?
              <span onClick={changeLocale('en')} className='header__locale-link'>
                ENG
              </span> :
              <span onClick={changeLocale('ru')} className='header__locale-link'>
                РУС
              </span>
            }
            <NavLink to='/checkout'>
              { replaceBucketIconWithWhiteIcon ? <BucketWhiteIcon title='Bucket' /> : <BucketIcon title='Bucket' /> }
              {
                bucketAmount ?
                <span className='header__cart-amount'>
                  {bucketAmount}
                </span> : null
              }
            </NavLink>
          </div>
          <nav className={navClass}>
            <ul>
              <li>
                <NavLink to='/' exact className='header__nav-link' activeClassName='header__nav-link--active'>
                  {headerData.home}
                </NavLink>
              </li>
              {/*}<li>
                <NavLink to='/sale' className={saleLinkClass} activeClassName='header__nav-link--active'>
                  {headerData.sale}
                </NavLink>
              </li>*/}
              <li>
                <NavLink to='/stuff' className='header__nav-link' activeClassName='header__nav-link--active'>
                  {headerData.indigoStuff}
                </NavLink>
              </li>
              <li>
                <NavLink to='/market' className='header__nav-link' activeClassName='header__nav-link--active'>
                  {headerData.market}
                </NavLink>
              </li>
              {/*}<li>
                <NavLink to='/poshlaya-molly' className='header__nav-link' activeClassName='header__nav-link--active'>
                  {headerData.pm}
                </NavLink>
              </li>
              <li>
                <NavLink to='/psychea' className='header__nav-link' activeClassName='header__nav-link--active'>
                  {headerData.psychea}
                </NavLink>
              </li>
              <li>
                <NavLink to='/emoland' className='header__nav-link' activeClassName='header__nav-link--active'>
                  {headerData.emoland}
                </NavLink>
              </li>*/}
              <li>
                <NavLink to='/contacts' className='header__nav-link' activeClassName='header__nav-link--active'>
                  {headerData.contacts}
                </NavLink>
              </li>
              {
                user ?
                <li>
                  <NavLink to='/orders' className='header__nav-link' activeClassName='header__nav-link--active'>
                    {headerData.orders}
                  </NavLink>
                </li> : null
              }
              <li>
                {
                  user ?
                  <NavLink to='/account' className='header__nav-link header__nav-link--login' activeClassName='header__nav-link--active'>
                    {headerData.account}
                  </NavLink> :
                  <NavLink to='/login' className='header__nav-link header__nav-link--login' activeClassName='header__nav-link--active'>
                    {headerData.login}
                  </NavLink>
                }
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }

  toggleNav = () => this.setState({ navOpened: !this.state.navOpened })

  closeNav = () => this.setState({ navOpened: false })
}

export default withRouter(Header)
